// Global Methods
require('waypoints/lib/noframework.waypoints')

export default {
  initWaypoint() {
    if (window.$(".inview").length) {
      let wrappers = document.querySelectorAll('.inview');
      [].forEach.call(wrappers, function(value) {
        let offset = value.getAttribute("data-offset")
          ? value.getAttribute("data-offset")
          : "90%";
        let easeType = value.getAttribute("data-ease")
          ? value.getAttribute("data-ease")
          : "fadeInUp";
        new Waypoint({
          element: value,
          handler: function(direction) {
            if (direction === 'down') {
              value.classList.add(easeType);
            }
          },
          offset: offset
        });
      });
    }
  }
}
