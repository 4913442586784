<template>
<footer>
  <div class="mb-4">
    <div class="wrapper">
      <div class="mx-auto mt-3">
        <Social />
      </div>
    </div>
  </div>
  <div class="legal">Copyright &copy; 2021-{{ new Date().getFullYear() }}. All Rights Reserved By Pixelated Ink.</div>
</footer>
</template>

<script>
import Social from '@/components/Social.vue'
export default {
  name: 'Footer',
  components: {
    Social
  }
}
</script>

<style lang="scss" scoped>
$mobile: 767px;
footer {
    padding: 3.5rem 2rem 1rem;
    position: relative;
    margin: 0;
    text-transform: uppercase;
    text-align: center;
    @media(max-width: $mobile) {
        margin: 0;
    }
    h3 {
        font-size: 2rem;
    }
    .legal {
        text-transform: uppercase;
        font-size: 0.8rem;
        opacity: 0.4;
    }
}
</style>
