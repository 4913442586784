<template>
<div id="team-section">
  <section id="header" class="container-fluid">
    <div class="d-flex justify-content-center align-items-center">
      <div class="logo-wrapper inview slow animated" data-ease="fadeInDown">
        <img class="img-fluid" src="@/assets/PI_logo.png" alt="MEET THE TEAM">
      </div>
      <div class="logo-title inview animated delay-1" data-ease="fadeInRight">
        <h1 class="semi mb-0">MEET THE TEAM</h1>
      </div>
    </div>
  </section>
  <section id="meet-team">
    <div class="team-wrapper">
      <div class="grid-item" :class="((team.position> 2) ? 'grid-group' + ((team.position == 5) ? ' blank' : ''): '')" v-for="team in teams" :key="team.id">
        <div class="card-item d-flex flex-row card-half-odd" v-if="team.position == 1">
          <div class="card-image zoom">
            <picture>
              <source :srcset="require(`@/assets/people/${team.image}`)" media="(min-width: 768px)">
              <img class="img-fluid w-100 zoom-image" :src="require(`@/assets/people/${team.mobile}`)" />
            </picture>
          </div>
          <div class="card-content d-flex align-items-center">
            <div class="wrapper">
              <h2 class="semi name inview slow animated">{{ team.name }}</h2>
              <div class="bar inview animated delay-1"></div>
              <div class="inview slow animated delay-2">
                <div class="title">{{ team.title }}</div>
                <div class="icon linkedin" v-if="team.linkedIn"><a :href="team.linkedIn" target="_blank" rel="noopener noreferrer" title="LinkedIn"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title" fill="#000"
                      aria-describedby="desc" role="img" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path data-name="layer1"
                        d="M1.15 21.7h13V61h-13zm46.55-1.3c-5.7 0-9.1 2.1-12.7 6.7v-5.4H22V61h13.1V39.7c0-4.5 2.3-8.9 7.5-8.9s8.3 4.4 8.3 8.8V61H64V38.7c0-15.5-10.5-18.3-16.3-18.3zM7.7 2.6C3.4 2.6 0 5.7 0 9.5s3.4 6.9 7.7 6.9 7.7-3.1 7.7-6.9S12 2.6 7.7 2.6z">
                      </path>
                    </svg></a></div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-item d-flex flex-row card-half-even" v-if="team.position == 2">
          <div class="card-content d-flex align-items-center">
            <div class="wrapper">
              <h2 class="semi name inview slow animated">{{ team.name }}</h2>
              <div class="bar inview animated delay-1"></div>
              <div class="inview slow animated delay-2">
                <div class="title">{{ team.title }}</div>
                <div class="icon linkedin" v-if="team.linkedIn"><a :href="team.linkedIn" target="_blank" rel="noopener noreferrer" title="LinkedIn"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title" fill="#000"
                      aria-describedby="desc" role="img" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path data-name="layer1"
                        d="M1.15 21.7h13V61h-13zm46.55-1.3c-5.7 0-9.1 2.1-12.7 6.7v-5.4H22V61h13.1V39.7c0-4.5 2.3-8.9 7.5-8.9s8.3 4.4 8.3 8.8V61H64V38.7c0-15.5-10.5-18.3-16.3-18.3zM7.7 2.6C3.4 2.6 0 5.7 0 9.5s3.4 6.9 7.7 6.9 7.7-3.1 7.7-6.9S12 2.6 7.7 2.6z">
                      </path>
                    </svg></a></div>
              </div>
            </div>
          </div>
          <div class="card-image zoom">
            <picture>
              <source :srcset="require(`@/assets/people/${team.image}`)" media="(min-width: 768px)">
              <img class="img-fluid w-100 zoom-image" :src="require(`@/assets/people/${team.mobile}`)" />
            </picture>
          </div>
        </div>
        <div class="card-item d-flex flex-row card-group" :class="((team.position == 3) ? 'team-odd' : 'team-even')" v-if="team.position == 3 || team.position == 4">
          <div class="card-image zoom mobile" :class="((team.id % 2 === 0) ? 'even' : 'odd')"><img class="img-fluid w-100 zoom-image" :src="require(`@/assets/people/${team.image}`)" /></div>
          <div class="card-content d-flex align-items-center" :class="((team.id % 2 === 0) ? 'odd' : 'even')">
            <div class="wrapper">
              <h2 class="semi name inview slow animated">{{ team.name }}</h2>
              <div class="bar inview animated delay-1"></div>
              <div class="inview slow animated delay-2">
                <div class="title">{{ team.title }}</div>
                <div class="icon linkedin" v-if="team.linkedIn"><a :href="team.linkedIn" target="_blank" rel="noopener noreferrer" title="LinkedIn"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title" fill="#000"
                      aria-describedby="desc" role="img" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <path data-name="layer1"
                        d="M1.15 21.7h13V61h-13zm46.55-1.3c-5.7 0-9.1 2.1-12.7 6.7v-5.4H22V61h13.1V39.7c0-4.5 2.3-8.9 7.5-8.9s8.3 4.4 8.3 8.8V61H64V38.7c0-15.5-10.5-18.3-16.3-18.3zM7.7 2.6C3.4 2.6 0 5.7 0 9.5s3.4 6.9 7.7 6.9 7.7-3.1 7.7-6.9S12 2.6 7.7 2.6z">
                      </path>
                    </svg></a></div>
              </div>
            </div>
          </div>
          <div class="card-image zoom desktop" :class="((team.id % 2 === 0) ? 'even' : 'odd')"><img class="img-fluid w-100 zoom-image" :src="require(`@/assets/people/${team.image}`)" /></div>
        </div>
        <div class="card-item card-group d-none d-md-block" v-if="team.position == 5">
          <div class="card-image"><img class="img-fluid w-100" src="@/assets/blank.png" /></div>
          <div class="blank-content d-flex align-items-center" :class="((team.id % 2 === 0) ? 'odd' : 'even')">
            <div class="icon text-center mx-auto"><img class="img-fluid" src="@/assets/white_skull_icon.png" /></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import people from '@/json/people.json'
import helpers from '@/scripts/helpers.js'
export default {
  name: 'Team',
  data: () => {
    return {
      teams: people
    }
  },
  mounted() {
    helpers.initWaypoint();
  }
};
</script>
