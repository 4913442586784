<template>
<div id="expanded-menu" :class="{show: show}">
  <div class="menu-block vh-100 d-flex align-items-center justify-content-center container-xl">
    <div class="wrapper">
      <ul class="navbar-nav text-center">
        <li class="nav-item mx-0 mx-lg-1">
          <a href="http://clementinesnightmare.io/" class="nav-link" target="_blank" rel="noopener noreferrer">Meet Clementine</a>
        </li>
        <li class="nav-item mx-0 mx-lg-1">
          <router-link class="nav-link py-2 mx-3" to="/" @click="toggleNav">Meet the Team</router-link>
        </li>
      </ul>
      <div class="mt-4">
        <Social />
      </div>
    </div>
  </div>
</div>
<div id="nav">
  <nav class="navbar navbar-expand text-uppercase fixed-top" id="mainNav">
    <div class="container-fluid">
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item mx-0 mx-lg-1">
            <a href="http://clementinesnightmare.io/" class="nav-link py-1 mx-2" target="_blank" rel="noopener noreferrer">Meet Clementine</a>
          </li>
          <li class="nav-item mx-0 mx-lg-1">
            <router-link class="nav-link py-1 mx-2" to="/meet-the-team" @click="scrollToTop">Meet the Team</router-link>
          </li>
        </ul>
      </div>
      <a class="navbar-brand ml-4" href="/"><img class="img-fluid" src="@/assets/brand_logo.svg" alt="Pixelated Ink" /></a>
    </div>
  </nav>
</div>
</template>

<script>
import Social from '@/components/Social.vue'
export default {
  components: {
    Social
  },
  data: function() {
    return {
      show: false,
      sitename: process.env.VUE_APP_SITE_NAME
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$nextTick(function() {
      window.addEventListener("scroll", function() {
        var navbar = document.getElementById("mainNav");
        var nav_classes = navbar.classList;
        if (document.documentElement.scrollTop >= 150) {
          if (nav_classes.contains("navbar-shrink") === false) {
            nav_classes.toggle("navbar-shrink");
          }
        } else {
          if (nav_classes.contains("navbar-shrink") === true) {
            nav_classes.toggle("navbar-shrink");
          }
        }
      })
    })
  },
  methods: {
    toggleNav: function() {
      this.show = !this.show;
      window.scrollTo(0, 0);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style lang="scss" scoped>
$tablet: 991px;
$phone: 420px;
nav.navbar {
    .navbar-brand {
        max-width: 110px;
        min-width: 70px;
        margin-right: 0;
        width: 12vw;
    }
    &.navbar-shrink {
        background-color: rgba(0,0,0,0.7);
        .navbar-brand {
            max-width: 100px;
            min-width: 100px;
        }
        a.nav-link {
            font-size: 1rem;
        }
    }
}
.navbar-nav {
    .nav-item {
        a.nav-link {
            position: relative;
            color: #fff;
            font-weight: 500;
            font-size: 0.9rem;
            @media (max-width: 767px) {
                font-size: 0.8rem;
            }
            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 0;
                height: 1px;
                background: #cd015a;
                transition: width 0.3s ease-in-out;
            }

            &.router-link-active,
            &:hover {
                color: #cd015a;

                &:before {
                    width: 100%;
                }
            }
        }
    }

}
</style>
