<template>
<main>
  <Menu />
  <router-view />
  <Footer />
</main>
</template>

<script>
import "@/styles/App.scss";
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Menu,
    Footer
  }
}
</script>
